// export const mode = "DEV";
export const mode = "PROD";

export const BASE_URL =
  mode === "PROD"
    ? "https://support-api.vytechenterprise.com"
    : "https://api.tickets.vytech.co";

export const BASE_URL_UPLOAD =
  mode === "PROD"
    ? "https://support-api.vytechenterprise.com/upload"
    : "https://api.tickets.vytech.co/upload";
